html {
  scroll-behavior: smooth;
}

body {
  background: #121212;
  margin: 30px 0;
}

.title {
  color: #39ff14;
  font-family: "Courier New", monospace;
  letter-spacing: 3px;
}

.titleContainer {
  text-align: center;
  font-size: 2rem;
}

/* Counter */

.Counter {
  text-align: center;
  color: white;
  font-family: Arial, sans-serif;
  font-size: 3rem;
  transform: translate(0%, -30%);
}

/* Board */

.boardContainer {
  /* display: flex; */
  display: flex;
  align-items: center;
}

.board {
  outline: 2px solid white;
  margin-left: auto;
  margin-right: auto;
}

.row {
  /* border: 1px solid white; */
  border: none;
}

.cell {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  display: inline-block;
  border-bottom: none;
  border: none;
  color: antiquewhite;
  margin-bottom: -4px;
}

.snake-cell {
  background-color: violet;
}

.food {
  background-color: red;
}

.highScoretext {
  color: white;
  text-align: center;
  font-family: "Courier New", monospace;
}

.gameOverText {
  color: red;
  text-align: center;
  font-family: "Courier New", monospace;
  letter-spacing: 2px;
  font-size: 4rem;
}

.btnContainer {
  text-align: center;
}
.playAgainBTN {
  border-radius: 5px;
  background-color: #058de7;
  color: white;
  font-size: 1.5rem;
  border: none;
  padding: 5px;
}

.gameContainer {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1024px) {
  /* Laptop */
  .gameContainer {
    display: flex;
    flex-direction: row;
  }
}
